export interface AuthConfigModel{
  title?:string,
  appName?:string, 
  poweredBy?:string,
  copyRightUrlRedirection?:string,
  customFeatures?:any,
  portalSiteUrl:string,
}

export class AuthConfig {
  public readonly title?:string;
  public readonly appName?:string;
  public readonly poweredBy?:string;
  public readonly copyRightUrlRedirection?:string;
  public readonly customFeatures?:string;
  public readonly portalSiteUrl?:string;
  constructor(
    config:AuthConfigModel
  ) {
    this.appName = config.appName;
    this.title = config.title;
    this.poweredBy = config.poweredBy;
    this.copyRightUrlRedirection = config.copyRightUrlRedirection;
    this.customFeatures = config.customFeatures;
    this.portalSiteUrl = config.portalSiteUrl;
  }
}
